<template>
  <Modal v-model="showDialog">
    <p slot="header">
      <Icon type="ios-information-circle"></Icon>
      <span>{{status}}充电桩设备ID</span>
    </p>
    <div>
      <Form ref="formValidate" :model="cpNoForm" :label-width="100">
        <template v-if="!cpNoItem">
          <FormItem label="设备ID数量">
            <Input type="number" v-model="cpNoForm.num" placeholder="请输入设备ID数量"></Input>
          </FormItem>
          <FormItem label="商户名称">
            <Select v-model="cpNoForm.merchantIdOfPile" filterable placeholder="请选择商户名称">
              <Option v-for="item in merchantList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </template>
        <template v-else>
          <FormItem label="充电桩编号">
            <Input v-model="cpNoForm.cpNo " disabled></Input>
          </FormItem>
          <FormItem label="商户名称">
            <Select v-model="cpNoForm.merchantId" filterable placeholder="请选择商户名称">
              <Option v-for="item in merchantList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem label="是否已用">
            <RadioGroup v-model="cpNoForm.status">
              <Radio :label="item.key" v-for="(item, index) in $options.cpNoStatusEnum" :key="index">
                {{item.value}}
              </Radio>
            </RadioGroup>
          </FormItem>
        </template>
      </Form>
    </div>
    <div slot="footer">
      <Button type="primary" :loading="loading" @click="save('handleAddCpNo')" v-if="!cpNoItem">添加充电桩设备ID
      </Button>
      <Button type="primary" @click="save('handleUpdateCpNo')" :loading="loading" v-else>修改充电桩设备ID</Button>
    </div>
  </Modal>
</template>

<script>
import dialogMixin from '_m/dialog.mixin'
import { cpNoStatusEnum } from '_e/index'

export default {
  name: 'CpNoDialog',
  mixins: [dialogMixin],
  cpNoStatusEnum,
  data () {
    return {
      loading: false,
      cpNoForm: {
        merchantIdOfPile: '',
        num: 1
      },
      merchantList: []
    }
  },
  mounted () {
    this.queryMerchantList()
  },
  watch: {
    showDialog (value) {
      if (!value) {
        this.handleResetFields()
      }
    },
    value (value) {
      if (value) {
        if (this.cpNoItem) {
          Object.assign(this.cpNoForm, this.cpNoItem)
        }
      }
    }
  },
  computed: {
    status () {
      return this.cpNoForm ? '修改' : '添加'
    }
  },
  props: {
    // 菜单项
    cpNoItem: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  methods: {
    /**
     * 重置表单域
     */
    handleResetFields () {
      this.$refs['formValidate'].resetFields()
    },
    // 保存到后端
    save (funcName) {
      this.loading = true
      this[funcName]().then((response) => {
        let { code } = response
        if (code === 1) {
          this.$emit('on-success')
          this.showDialog = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 添加表单项
     */
    handleAddCpNo () {
      return this.$http.get('/server/v1/CpNo/generate', {
        params: this.cpNoForm
      })
    },
    /**
     * 修改表单项
     */
    handleUpdateCpNo () {
      return this.$http.post(`/server/v1/CpNo/update`, this.cpNoForm)
    },
    // 获取商家列表
    queryMerchantList () {
      this.$http.post('/server/v1/Merchant/page', {
        pageNo: 1,
        pageSize: 100,
        t: {
          status: 0
        }
      }).then((response) => {
        const { code, data } = response
        if (code === 1) {
        let { rows, totalRows, code, msg } = data
                if (rows) {
                  this.merchantList = rows
                  //this.total = totalRows
                }
        } else {
          this.$Notice.error({
            title: `接口报错:code=${code}`,
            desc: msg
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
</style>

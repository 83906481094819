<template>
    <div>
        <!--表单模态框-->
        <cp-no-dialog v-model="showDialog" :cp-no-item="currentCpNo" @on-success="handleSuccess"/>
        <Modal v-model="showShengji" @on-ok="handleUpdate">
            <Form :model="formItem" :label-width="100" label-position="left" style="margin-top:20px">
            <FormItem label="桩企自定义">
                <RadioGroup v-model="formItem.type">
                    <Radio label="1">主板</Radio>
                    <Radio label="2">辅板</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="文件名">
                <Input v-model="formItem.url" placeholder="输入文件名例如yjac.bin"></Input>
            </FormItem>
            </Form>
        </Modal>
        <Card ref="header">
            <div style="display: flex">
                <!-- <Input v-model="queryCpNoReq.cpNo" placeholder="输入充电桩编号" style="width:16%;margin-right:10px">
                  <Button slot="append" icon="ios-search" @click="initData" ></Button>
                </Input>
                <Input v-model="queryCpNoReq.merchantName" placeholder="输入商家名称" style="width:16%;margin-right:10px">
                  <Button slot="append" icon="ios-search" @click="initData" ></Button>
                </Input> -->
                <Input v-model="queryCpNoReq.cpNo" style="width:16%;margin-right:10px"><span slot="prepend">充电桩编号</span></Input>
                <Input v-model="queryCpNoReq.merchantName" style="width:16%;margin-right:10px"><span slot="prepend">商家名称</span></Input>
                <DatePicker type="daterange" placement="bottom-end" placeholder="选择日期" style="width: 250px;"
                 @on-change="handleDate" v-model="expireTime" format="yyyy-MM-dd"></DatePicker>
                <i-select v-model="queryCpNoReq.assignStatus" placeholder="选择状态" @on-change="handleSearch" style="width: 100px;margin:0 15px">
                  <i-option v-for="item in assignStatus" :value="item.key" :key="item.key">{{ item.value }}</i-option>
                </i-select>
                <i-select v-model="queryCpNoReq.useStatus" placeholder="选择状态" @on-change="handleSearch" style="width: 100px;">
                  <i-option v-for="item in useStatus" :value="item.key" :key="item.key">{{ item.value }}</i-option>
                </i-select>
                <Button type="primary" @click="initData" style="float: right; margin-left: 10px">
                 <Icon type="ios-search" />查询
                </Button>
                <Button type="primary" icon="md-add" style="margin-left: auto;" @click="handleAddCpNo">添加</Button>
            </div>
        </Card>
        <!--表格-->
        <Table row-key="id" :tooltip-theme="$store.state.theme" :height="tableHeight" :loading="loading"
               :columns="tableColumns" :data="tableData" stripe border></Table>

        <!-- 分页 -->
        <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
              @on-page-size-change="handlePageSize" style="float: right;"/>
        <Modal
        v-model="showWebsocket"
        title="解析后实时报文">
        <div class="webSocket">
        <p v-if="spinShow">连接中，请稍后</p>
        <p v-for="(item, index) in webList" :key="index">{{item}}</p>
        </div>
        <div slot="footer">
            <Button type="error" size="large" long @click="del">关闭报文</Button>
        </div>
        </Modal>
    </div>
</template>

<script>
    import tableHeightMixin from '_m/tableHeight.mixin'
    import tablePageMixin from '_m/tablePage.mixin'
    import { cpNoStatusEnum } from '_e/index'
    import { handleGetEnumValue, getDateTime } from '@/utils'
    import CpNoDialog from './CpNoDialog'

    export default {
        name: 'CpNo',
        mixins: [tableHeightMixin, tablePageMixin],
        components: {
            CpNoDialog
        },
        data () {
            return {
                tableData: [],
                showDialog: false,
                currentCpNo: null,
                showWebsocket: false,
                showShengji: false,
                webList: [],
                spinShow: true,
                sock: null,
                formItem: {
                    cpNo: '',
                    type: '1',
                    url: ''
                },
                expireTime: [getDateTime(-30) + ' ' + '00:00:00', getDateTime(0) + ' ' + '00:00:00'],
                queryCpNoReq: {
                   assignStatus: 1,
                   cpNo: '',
                   merchantName: '',
                   useStatus: 1,
                   edate: '',
                   sdate: ''
                },
                disabled: false,
                assignStatus: [
                    { key: 1, value: '已分配' },
                    { key: 0, value: '未分配' }
                ],
                useStatus: [
                    { key: 1, value: '已使用' },
                    { key: 0, value: '未使用' }
                ],
            }
        },
        created () {
            this.initData()
        },
        watch: {
            showWebsocket(val) {
                if (!val) {
                    this.sock.close()
                    this.sock.onclose = () => {
                    console.log('websocket-close')
                    this.spinShow = true
                    this.webList = []
                }
                }
            }
        },
        computed: {
            tableColumns () {
                let columns = []
                columns.push({
                    title: '充电桩编号',
                    key: 'cpNo',
                    align: 'center',
                })
                columns.push({
                    title: '商家名称',
                    key: 'merchantName',
                    align: 'center',
                })
                columns.push({
                    title: '使用状态',
                    align: 'center',
                    render: (h, params) => {
                        const { row } = params
                        const enumValue = handleGetEnumValue(cpNoStatusEnum, row.merchantStatus)
                        return h('Tag', {
                            props: {
                                color: row.merchantStatus ? 'success' : 'default'
                            }
                        }, enumValue)
                    }
                })
                columns.push({
                    title: '分配状态',
                    align: 'center',
                    render: (h, params) => {
                        const { row } = params
                        const text = row.status ? '分配' : '未分配'
                        return h('Tag', {
                            props: {
                                color: row.status ? 'success' : 'default'
                            }
                        }, text)
                    }
                })
                columns.push({
                    title: '操作',
                    align: 'center',
                    width: 240,
                    render: (h, params) => {
                        const { row } = params
                        const array = []
                        array.push(h('Button', {
                            style: {
                                marginRight: '5px'
                            },
                            props: {
                                icon: 'ios-cog',
                                type: 'warning',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleUpdateCpNo(row)
                                }
                            }
                        }, '编辑'))
                        array.push(h('Button', {
                            style: {
                                marginRight: '5px'
                            },
                            props: {
                                icon: 'ios-cog',
                                type: 'info',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleShenji(row)
                                }
                            }
                        }, '升级'))
                        array.push(h('Button', {
                            style: {
                                marginRight: '5px'
                            },
                            props: {
                                icon: 'ios-cog',
                                type: 'success',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleConnectWebSock(row)
                                }
                            }
                        }, '报文'))
                        return h('div', { style: {
                                display: 'flex',
                                flexWrap: 'no-wrap'
                            } }, array)
                    }
                })
                return columns
            }
        },
        methods: {
            initData () {
                this.queryCpNoReq.edate = new Date(this.expireTime[1])
                this.queryCpNoReq.sdate = new Date(this.expireTime[0])
                this.loading = true
                this.$http.post(`/portal/v1/biz-cp-no/m/findCpByPage?page=${this.currentPage}&pageCount=${this.pageSize}`, this.queryCpNoReq).then((res) => {
                    let { data, code, msg } = res
                    if (code === 1) {
                        this.tableData = data.records
                        this.total = data.total
                    } else {
                        this.$Notice.error({
                            title: `接口报错:code=${code}`,
                            desc: msg
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleDate (val) {
               this.expireTime[0] = val[0] + ' ' + '00:00:00'
               this.expireTime[1] = val[1] + ' ' + '00:00:00'
               this.initData()
            },
            handleShenji (row) {
                this.showShengji = true
                this.formItem.cpNo = row.cpNo
            },
            handleUpdate() {
                this.$http.post(`/portal/v1/biz-charge-pile/m/cpUpdate`, this.formItem).then((response) => {
                    console.log(response)
                    if (response.code === 1) {
                        this.$Notice.success({
                            title: `升级成功`,
                            desc: response.msg
                        })
                    } else {
                        this.$Notice.error({
                            title: `升级失败`,
                            desc: response.msg
                        })
                    }
                })
            },
            handleConnectWebSock(row) {
                this.showWebsocket = true
                this.sock = new WebSocket(`wss://charge.gzninuo.com/portal/ws/cp/${row.cpNo}/${this.$store.state.auth.token}`)
                this.sock.onopen = () => {
                    console.log('websocket-open')
                }
                this.sock.onmessage = (event) => {
                    const data = event.data
                    if (data) {
                        try {
                               this.spinShow = false
                               this.webList.push(data)
                        } catch (e) {
                            console.error(e)
                        }
                    }
                }
            },
            del() {
                console.log('sdvdf', this.sock)
                this.sock.close()
                this.sock.onclose = () => {
                    console.log('websocket-close')
                    this.spinShow = true
                    this.showWebsocket = false
                    this.webList = []
                }
            },
            handleDisConnectWebSocket () {
                this.sock && this.sock.close()
            },
            handleSuccess () {
                this.initData()
            },
            // 添加角色
            handleAddCpNo () {
                this.currentCpNo = null
                this.showDialog = true
            },
            // 修改角色
            handleUpdateCpNo (item) {
                this.currentCpNo = item
                this.showDialog = true
            }
        }
    }
</script>

<style scoped>
    .menu-option {
        display: flex;
        margin-bottom: 10px;
    }

    .m-t {
        margin-top: 16px;
    }
    .webSocket {
        height: 300px;
        overflow-y: scroll;
        background: #000;
        color: #ffffff;
    }
</style>
